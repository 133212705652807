import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('../../pages/Home'));
const Article = React.lazy(() => import('../../pages/Article'));
const WhatsNext = React.lazy(() => import('../../pages/WhatsNext'));

const App = () => {
  return (
    <React.Suspense>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/who-leads-matters' element={<Article />} />
          <Route path='/expanding-access' element={<Article />} />
          <Route path='/schools-and-systems' element={<Article />} />
          <Route path='/whats-next' element={<WhatsNext />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
